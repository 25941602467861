import * as React from 'react';
import loadable from '@loadable/component';
import { Route, Switch } from 'react-router';

import { DeliveryContextProvider } from '../../../utils/context/DeliveryContext';

import { PageLoaderFallback } from '../../layout/PageLoaderFallback';

const Main = loadable(() => import(/* webpackPrefetch: true */ './Main'), {
  fallback: <PageLoaderFallback />
});

const Article = loadable(
  () => import(/* webpackPrefetch: true */ './Article'),
  {
    fallback: <PageLoaderFallback />
  }
);

const NoMatch = loadable(() => import(/* webpackPrefetch: true */ '../404Old'));

const Delivery: React.FC = () => {
  return (
    <Switch>
      <Route
        exact
        path="/leveransebeskrivelse-selge-bolig/:type(fritid)?"
        render={(props) => {
          return (
            <DeliveryContextProvider {...props}>
              <Main />
            </DeliveryContextProvider>
          );
        }}
      />
      <Route
        exact
        path="/leveransebeskrivelse-selge-bolig/:type(fritid)/:slug"
        render={(props) => {
          return (
            <DeliveryContextProvider {...props}>
              <Article />
            </DeliveryContextProvider>
          );
        }}
      />
      <Route
        exact
        path="/leveransebeskrivelse-selge-bolig/:slug"
        render={(props) => {
          return (
            <DeliveryContextProvider {...props}>
              <Article />
            </DeliveryContextProvider>
          );
        }}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};

export default Delivery;
